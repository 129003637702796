import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import ContactUsForm from "components/forms/ContactFormJasmim";
import ContactFeatures from "components/features/DashedBorderFeaturesJasmim";
import { SectionHeading } from "components/misc/Headings.js";
import WhatsappButton  from "components/misc/WhatsappButton";

import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as EmailIcon } from "feather-icons/dist/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "feather-icons/dist/icons/smartphone.svg";

const Description = tw.div`m-auto w-full md:w-2/3 2xl:w-1/2 mt-8 text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Heading = tw(SectionHeading)`w-full mt-12 md:mt-24`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const EmailContainer = tw.div`leading-none`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <WhatsappButton />
      <Header />
      <Heading>Queremos <HighlightedText>ouvir você!</HighlightedText></Heading>
      <Description>
        Talvez seu corpo e sua mente estejam te enviando sinais sobre sua saúde. 
        Talvez você esteja com sintomas, mas não saiba a causa. Queremos ouvir você, suas queixas. 
        Oferecemos terapias de saúde integrativa, que vê e trata o Ser como um todo. Do que você precisa? 
        Fale com a gente!
      </Description>
      <ContactFeatures        
        cards = {[
          {
            icon: <PhoneIcon />, 
            title: "(31) 99456-8817",
            description: "Telefone e whatsapp",
            url: "tel:31994568817"
          },
          { icon: <LocationIcon />, 
            title: <>Rua Pirapetinga, 322 / Sala 403<br />Serra - Belo Horizonte/MG</>,
            description: "Localizado no Edifício Bérgamo",
            url: "https://goo.gl/maps/yyf6MjupTDEhXwXq6"
          },
          { 
            icon: <EmailIcon />,
            title: "Email",
            description: <EmailContainer>contato@<br />jasmimflorescerterapeutico.com.br</EmailContainer>,
            url: "mailto:contato@jasmimflorescerterapeutico.com.br"
          }
        ]} 
      />
      <ContactUsForm
        subheading = "Fale Conosco"
        heading = {<>Envie uma <span tw="text-primary-500">mensagem</span><wbr/> pra gente</>}
        description = {<>Nós responderemos assim que possível.<br />Caso queira mais agilidade nos envie uma mensagem no <a rel="noreferrer" href="https://api.whatsapp.com/send/?phone=+5531994568817&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank" tw="text-primary-500 hover:text-primary-900">whatsapp</a>.</>}
        submitButtonText = "Enviar"
        formAction = "#"
        formMethod = "get"
      />
      <Footer />
    </AnimationRevealPage>
  );
};
