import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import PhotoGallery from "components/gallery/PhotoGallery.js";
import Therapies from "components/features/VerticalWithAlternateImageAndText.js";
import HeadingWithDescription from "components/misc/HeadingWithDescription.js";
import WhatsappButton from "components/misc/WhatsappButton";

import TherapyCover from "images/photos/Jasmim - Fotos 103.jpg";

import TherapyFeatureAcupuncture from "images/photos/430x576/acupuntura-430x576.jpg";
import TherapyFeatureAyurveda from "images/photos/430x576/massagem-pes-oleos-ayurveda-430x576.jpg";
import TherapyFeatureReiki from "images/photos/430x576/reiki-430x576.jpg";
import TherapyFeatureMassage from "images/photos/430x576/massagem-430x576.jpg";
import TherapyFeatureNatural from "images/photos/430x576/pedras_quentes-430x576.jpg";
import TherapyFeatureDrainage from "images/photos/430x576/drenagem-linfatica-430x576.jpg";

//Photos
import Photo1 from "images/photos/Jasmim - Fotos 049.jpg";
import Photo2 from "images/photos/Jasmim - Fotos 052.jpg";
import Photo3 from "images/photos/Jasmim - Fotos 064.jpg";
import Photo4 from "images/photos/Jasmim - Fotos 070.jpg";
import Photo5 from "images/photos/Jasmim - Fotos 073.jpg";
import Photo6 from "images/photos/Jasmim - Fotos 081.jpg";
import Photo7 from "images/photos/Jasmim - Fotos 085.jpg";
import Photo8 from "images/photos/Jasmim - Fotos 099.jpg";
import Photo9 from "images/photos/Jasmim - Fotos 103.jpg";
import Photo10 from "images/photos/Jasmim - Fotos 105.jpg";
import Photo11 from "images/photos/Jasmim - Fotos 108.jpg";
import Photo12 from "images/photos/Jasmim - Fotos 172.jpg";
import Photo13 from "images/photos/Jasmim - Fotos 173.jpg";
import Photo14 from "images/photos/Jasmim - Fotos 190.jpg";
import Photo15 from "images/photos/Jasmim - Fotos 191.jpg";
import Photo16 from "images/photos/Jasmim - Fotos 201.jpg";
import Photo17 from "images/photos/Jasmim - Fotos 241.jpg";
import Photo18 from "images/photos/Jasmim - Fotos 245.jpg";
import Photo19 from "images/photos/Jasmim - Fotos 263.jpg";
import Photo20 from "images/photos/Jasmim - Fotos 266.jpg";
import Photo21 from "images/photos/Jasmim - Fotos 269.jpg";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const CitationText = tw.div`px-12 italic m-3 text-gray-500`;
const CitationAuthor = tw.div`text-sm text-gray-400`;
const Description = tw.div`text-justify text-sm`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const SpaceDiv = tw.div`mb-24`;

const photos = [
  {
    src: Photo1,
    width: 1,
    height: 1
  },
  {
    src: Photo2,
    width: 1,
    height: 1
  },
  {
    src: Photo3,
    width: 1,
    height: 1
  },
  {
    src: Photo4,
    width: 1,
    height: 1
  },
  {
    src: Photo5,
    width: 1,
    height: 1
  },
  {
    src: Photo6,
    width: 1,
    height: 1
  },
  {
    src: Photo7,
    width: 1,
    height: 1
  },
  {
    src: Photo8,
    width: 1,
    height: 1
  },
  {
    src: Photo9,
    width: 1,
    height: 1
  },
  {
    src: Photo10,
    width: 1,
    height: 1
  },
  {
    src: Photo11,
    width: 1,
    height: 1
  },
  {
    src: Photo12,
    width: 1,
    height: 1
  },
  {
    src: Photo13,
    width: 1,
    height: 1
  },
  {
    src: Photo14,
    width: 1,
    height: 1
  },
  {
    src: Photo15,
    width: 1,
    height: 1
  },
  {
    src: Photo16,
    width: 1,
    height: 1
  },
  {
    src: Photo17,
    width: 1,
    height: 2
  },
  {
    src: Photo18,
    width: 1,
    height: 2
  },
  {
    src: Photo19,
    width: 1,
    height: 1
  },
  {
    src: Photo20,
    width: 1,
    height: 1
  },
  {
    src: Photo21,
    width: 1,
    height: 1
  }
];

export default () => {
  return (
    <AnimationRevealPage>
      <WhatsappButton />
      <Header />
      <MainFeature1
        subheading={<Subheading>Jasmim Espaço Terapêutico</Subheading>}
        heading = {<>Do que <HighlightedText>você precisa?</HighlightedText></>}
        description = {
          <Description>
            Nós queremos te ajudar a passar por todas as etapas da sua vida com sabedoria, amor e expressão da sua criatividade e beleza. 
            Lembrando que você é antes de tudo uma semente, cheia de coisa boa pra florescer! 
            E para você, assim como para as árvores, é necessário passar pelas etapas de preparar o solo, romper a terra e brotar, crescer, florescer, dar frutos! 
            E esse é um ciclo natural e contínuo. Está na natureza e em você. Então o processo é seguir em frente com a liberdade de saber 
            quem se é e o que se quer, e saber também recuar quando for preciso, com saúde e respeito por si e por tudo o que vive.

            <CitationText>
              O Ayurveda nos ensina a valorizar nossa natureza inata – amar e honrar quem somos, não como o que as pessoas pensam ou nos dizem que devemos ser.
              <CitationAuthor>– Prana Gogia, Acupunturista e Médico Ayurvédico </CitationAuthor>
            </CitationText>

            

            As ferramentas chave são as terapias manuais corporais – massagens, acupuntura, Reiki, associadas aos elementos da natureza, como óleos, 
            ervas, flores, fitoterapia, aromaterapia, cristais e pedras. Para cada etapa, estímulos e composições alquímicas diferentes. Dentro do espaço-tempo de cada um!
          </Description>
        }
        buttonRounded={false}
        primaryButtonText={false}
        primaryButtonUrl="/terapias"
        imageSrc = {TherapyCover}
        textOnLeft={false}
      />
      <Therapies 
        title = {<>Nossas <HighlightedText>Terapias</HighlightedText></>}
        description = "Conheça um pouco mais sobre alguns dos nossos principais tratamentos."
        cards = {[
          {
            imageSrc: TherapyFeatureAcupuncture,
            subtitle: "Técnica chinesa",
            title: "Acupuntura",
            description:
              "A acupuntura é uma terapia milenar de origem chinesa, que consiste na aplicação de agulhas bem finas, em pontos específicos do corpo, para melhorar a imunidade e tratar problemas emocionais e doenças físicas como sinusite, asma, enxaqueca, dores, artrite, insônia, regulação intestinal, TPM, menopausa, ansiedade, depressão.",
            url: "/terapias/acupuntura"
          },

          {
            imageSrc: TherapyFeatureAyurveda,
            subtitle: "Sistema de cura indiano",
            title: "Ayurveda",
            description:
              "É o mais antigo sistema de cura do mundo, a ciência da vida, possui dois objetivos principais: preservar e promover a saúde das pessoas saudáveis e tratar as pessoas em processo de adoecimento físico e mental. Auxilia o indivíduo a despertar para a saúde integral (autocuidado, cuidado com o outro e cuidado com o ambiente em que se vive), através da alimentação, da prática de cuidados e rotinas diários, e de terapias e massagens.",
            url: "/terapias/ayurveda"
          },

          {
            imageSrc: TherapyFeatureDrainage,
            subtitle: "Elimina toxinas",
            title: "Drenagem Linfática",
            description:
              "É uma técnica especializada, que atua no sistema linfático, eliminando toxinas e fortalecendo o sistema imunológico. Drena líquidos acumulados entre as células, melhorando a circulação e a oxigenação desse tecido. É considerada um elixir para a saúde do corpo, além de ser muito relaxante.",
            url: "/terapias/drenagem"
          },
        ]} 
      />
      <Therapies 
        title = ""
        description = ""
        leftStart = {false}
        cards = {[
          {
            imageSrc: TherapyFeatureMassage,
            subtitle: "Qualidade de vida",
            title: "Massoterapia",
            description:
              "As massagens são ferramentas-chave em nossas terapias. Além dos efeitos relaxantes, atuam com grande precisão em curas, apresentando melhores resultados para tratar sinais e sintomas específicos, colaborando com o funcionamento do organismo, ajudando com a recuperação de possíveis traumas, dores, restabelecendo o equilíbrio e a saúde e aumentando a qualidade de vida.",
            url: "/terapias/massoterapia"
          },

          {
            imageSrc: TherapyFeatureReiki,
            subtitle: "Terapia japonesa",
            title: "Reiki",
            description:
              "É uma terapia integrativa, em que o terapeuta (ou mestre reikiano) estende suas mãos sob partes do corpo do paciente para canalizar energia vital universal (Prana), desbloqueando padrões negativos, a fim de restaurar o equilíbrio físico, regularizar suas funções vitais e equilibrar o campo mental e emocional. Reduz ansiedade, estresse, insônia, dores, TPM e outros, trazendo paz e saúde.",
            url: "/terapias/reiki"
          },

          {
            imageSrc: TherapyFeatureNatural,
            subtitle: "O poder da natureza",
            title: "Terapias Naturais",
            description:
              "Terapias naturais são aquelas que utilizam os recursos disponíveis na natureza ou que utilizam métodos que não agridem o organismo para promover a saúde, curar e prevenir doenças.",
            url: "/terapias/naturais"
          },
        ]} 
      />
      <HeadingWithDescription
        subheading="Em ação!"
        heading="Galeria"
        description="Conheça um pouco sobre o nosso trabalho."
      />
      <PhotoGallery photos={photos} />
      <SpaceDiv />
      <Footer />
    </AnimationRevealPage>
  );
};
