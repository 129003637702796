import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/ayurveda-massagem-oleos.jpg';
import Photo2 from 'images/ayurveda-itens-500x500.jpg';
import Photo3 from 'images/photos/Jasmim - Fotos 099-500x333.jpg';

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;


const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/ayurveda" secondTitle="Ayurveda" />
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Ayurveda</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} className="rightImage" align="right" />
            <p>É o mais antigo sistema de cura do mundo, a ciência da vida, possui dois objetivos principais: preservar e promover a saúde das pessoas saudáveis e tratar as pessoas em processo de adoecimento físico e mental. Auxilia o indivíduo a despertar para a saúde integral (autocuidado, cuidado com o outro e cuidado com o ambiente em que se vive), através da alimentação, da prática de cuidados e rotinas diários, e de terapias e massagens.</p>           
            
            <div className="clearfix" />
            <h1>Consulta Ayurvédica</h1>
            <div className="clearfix" />
            <img src={Photo2} className="leftImage" align="left" />
            <p>Através da consulta com a terapeuta ayurvédica, é desenvolvido o planejamento de práticas e rotinas diárias (Dinacharya) levando em conta as suas particularidades físicas, mentais, emocionais e espirituais (natureza ou doshas); o ambiente em que vive; trabalho e relacionamentos. O objetivo é saúde integral e longevidade, restaurando possíveis desequilíbrio e incluindo diversos benefícios:</p>
            <ul>
              <li>Perda de peso saudável;</li>
              <li>Redução de inflamações;</li>
              <li>Redução do estresse;</li>
              <li>Pele e cabelos mais saudáveis e brilhantes;</li>
              <li>Eliminação de toxinas.</li>
            </ul>
            
            <div className="clearfix" />
            <h1>Massagens Ayurvédicas</h1>
            <div className="clearfix" />
            <h2>Abhyanga</h2>
            <p>Massagem com óleos medicados e aquecidos, para aquietar a mente, alimentar o sistema nervoso, purificar e eliminar as toxinas, ativar a circulação sanguínea e melhorar a imunidade. Trabalhando em conjunto os marmas, reduz dores musculares e articulares, melhora a marcha (caminhada), reduz edemas, congestões e estagnações pelo corpo.</p>
            <img src={Photo3} className="rightImage" align="right" />
            <h2>Shiroabhyanga</h2>
            <p>Abhyanga realizada na cabeça. É feita a oleação e são trabalhados os pontos marmas da cabeça, face e pescoço.</p>            
            <h2>Padhabhyanga</h2>
            <p>Abhyanga realizada nos pés. É feita a oleação e são trabalhados os pontos marmas dos pés.</p>            
            <h2>Garshana</h2>
            <p>É aplicado óleo medicado e aquecido na pele, seguido de uma massagem esfoliante e purificante com sal grosso e ervas frescas. Finalizando com uma swedana (sauna seca, manta térmica, sauna a vapor ou banho de imersão). É uma massagem de redução, para eliminar o excesso de água do organismo. Elimina toxinas, traz leveza e bem-estar. Reduz dores. Reduz gordura e celulite.</p>
            <h2>Udwartana</h2>
            <p>É aplicado óleo medicado e aquecido na pele, seguido de uma massagem esfoliante com pós de ervas especiais com a finalidade do que se quer tratar. Finalizando com uma swedana (sauna seca, manta térmica ou sauna a vapor ou banho de imersão). É uma massagem de redução, para eliminar o excesso de água do organismo. Elimina toxinas, traz fluidez e movimento. Reduz dores. Reduz gordura e celulite. Reduz edemas, congestões e estagnações pelo corpo. Tonifica e dá viço a pele.</p>

            <div className="clearfix" />
            <h1>Terapias Ayurvédicas</h1>
            <div className="clearfix" />
            
            <h2>Shirodhara</h2>
            <p>Fluxo contínuo de líquido medicinal aquecido (óleo, leite, decocto, ghee medicados) na região da testa, entre as sobrancelhas, sobre o AJNA Chacra (Sthapani marma). Pode ser usada como purificador ou como tratamento para acalmar e centrar a mente, insônia, enxaquecas, estresse, ansiedade, problemas neurológicos, tremores de Parkinson, gastrite, fadiga, problemas articulares, falta de vitalidade, pressão alta.</p>
            <h2>Pindas Swedana e Patra Potali</h2>
            <p>São terapias de suor (swedana). São aplicação de trouxinhas com arroz medicado com decocto de ervas ou trouxinha com ervas e plantas frescas picadas e refogadas em óleos. Com objetivo de nutrir, fortalecer, rejuvenescer. O calor das trouxinhas dilata os poros promovendo uma boa absorção das ervas. Usada para dores e degenerações articulares, insônia, atrofia muscular, osteoporose, tensões e espasmos musculares, doenças neurológicas, baixa libido, intestino preso, fadiga, cansaço, contraturas musculares, torcicolo, processos inflamatórios com edemas.</p>            
            <h2>Bastis externos</h2>
            <p>São procedimentos incluídos no grupo de oleação (snehana), nos quais uma substância oleosa (óleos ou ghee medicados com ervas ou puros) são aplicados externamente, contidos dentro de uma estrutura formada por uma massa composta por farinha trigo integral ou grão de bico, em uma área epecífica do corpo. Usados para dores e degenerações articulares, angústia, ansiedade, cólica, gases, TPM, dores na coluna, joelhos.</p>            
            <h2>Nasya/Karnapurana</h2>
            <p>Aplicação de substâncias via nasal e via ouvido. Elimina os excessos da cabeça, limpa e clareia os sentidos, por eliminar toxinas e rejuvenesce os tecidos dessa região (cérebro) por nutrir. Os óleos e ervas são escolhidos de acordo com a necessidade do paciente.</p>
            <h2>Banhos Terapêuticos de imersão</h2>
            <p>São utilizados decoctos de ervas e flores, óleos essenciais e cromoterapia.</p>
            <h2>Swedana</h2>
            <p>Sauna Ayurvédica</p>

            <div className="clearfix" />
            <h1>Rasayana / Vajikarana</h1>
            
            <p>Terapia de Rejuvenescimento, Revitalização Ayurvédica. Utiliza se ervas, alimentos e massagens nutritivas para estimular a vitalidade. Trabalha a nutrição e a construção de tecidos saudáveis, equilibrando corpo e mente. Atua na libido e prazer de viver.</p>

            <div className="clearfix" />
            <h1>Detox / Amapachana</h1>
            
            <p>Faz parte da rotina anual, ou sazonal (Ritucharya). Procedimento importante para manter a saúde do organismo. É uma limpeza feita através de alimentação e procedimentos externos como massagens para oleação e sauna (snehana e swedana) que carregam as toxinas para eliminação. A duração varia de acordo com o dosha, os desequilíbrios e a necessidade do paciente. O objetivo é eliminar toxinas, desbloquear os canais de circulação do corpo e da mente, melhorar a digestão (AGNI) , limpar o fígado e os intestinos e clarear a mente. Mantendo a leveza, a fluidez e a saúde como um todo.</p>

            <div className="clearfix" />
            <h1>Yoga e Ayurveda</h1>
            
            <p>Yoga significa união em sânscrito, e Ayurveda pode ser definido como a sabedoria da vida. Exploradas juntas, essas práticas complementares podem nos oferecer ferramentas transformadoras que promovem maior saúde e vitalidade. A integração dos princípios ayurvédicos em sua prática de ioga pode criar uma experiência mais profunda e rica não só durante sua atividade, mas também na vida.</p>
            <h2>Benefícios do Yoga</h2>
            <p>A ayurveda aliada ao yoga proporciona diversos benefícios à saúde:</p>
            <ul>
              <li>Diminui o estresse e a ansiedade;</li>
              <li>Promove o condicionamento físico;</li>
              <li>Facilita o emagrecimento saudável;</li>
              <li>Alivia dores corporais;</li>
              <li>Melhora a respiração.</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
