import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/photos/Jasmim-Fotos-098-370x208.jpg';
import Photo2 from 'images/photos/Jasmim-Fotos-103-600x400.jpg';
import Photo3 from 'images/photos/Jasmim-Fotos-156-500x500.jpg'

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;

const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/massoterapia" secondTitle="Massoterapia" />
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Massoterapia</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} className="leftImage" align="left" />
            <p>As massagem são ferramentas-chave em nossas terapias. Além dos efeitos relaxantes, atuam com grande precisão em curas, apresentando melhores resultados para tratar sinais e sintomas específicos, colaborando com o funcionamento do organismo, ajudando com a recuperação de possíveis traumas, dores, restabelecendo o equilíbrio e a saúde e aumentando a qualidade de vida.</p>           
            
            <div className="clearfix" />
            <h1>Massagem relaxante</h1>            
            <div className="clearfix" />
            <img src={Photo2} className="rightImage" align="right" />
            <p>É uma técnica que mescla movimentos firmes e suaves sobre o corpo. Esses movimentos estimulam a produção de hormônios relaxantes, como a ocitocina, que melhora o estresse e a sensação de medo, assim como a serotonina, que normaliza o humor, os batimentos cardíacos e a sensibilidade em relação à dor. A massagem oferece vários benefícios:</p>
            <ul>
              <li>Reduz a tensão muscular;</li>
              <li>Melhora a circulação;</li>
              <li>Estimula do sistema linfático;</li>
              <li>Reduz os hormônios do estresse;</li>
              <li>Aumento da mobilidade e flexibilidade das articulações;</li>
              <li>Auxilia na recuperação de lesões de tecidos;</li>
              <li>Melhora a concentração;</li>
              <li>Reduz a ansiedade.</li>
            </ul>

            <div className="clearfix" />
            <h1>Massagens Terapêuticas</h1>
            <div className="clearfix" />
            
            <h2>Tui Ná</h2>
            <p>É um método de massagem terapêutica chinesa que promove uma melhora na circulação sanguínea, regulando as funções dos órgãos, lubrificando tendões e músculos e equilibrando a energia do organismo. Permite prevenir e tratar patologias, como tensões e dores  musculares; dores irradiadas por inervação, como ciático; ansiedade; estresse; promovendo um bem estar físico e mental.</p>            
            <h2>Shiatsu</h2>
            <p>É uma técnica de massagem terapêutica japonesa que visa ao equilíbrio entre corpo e mente, através da pressão dos dedos ou palmas das mãos realizada em determinadas áreas do corpo.</p>            
            <h2>Pedras quentes</h2>
            <img src={Photo3} className="rightImage" align="right" />
            <p>É uma massagem feita com pedras de basalto quentes em todo o corpo, inclusive rosto, que ajuda a relaxar e a aliviar o estresse acumulado durante as tarefas diárias. Inicialmente é feita uma massagem em todo o corpo com bastante óleo e, a seguir, o terapeuta realiza também uma suave massagem com a pedra aquecida deixando-a pousada, durante alguns minutos, em alguns pontos específicos do corpo, chamados de pontos-chave de acupressão.</p>
            <h2>Bambuterapia</h2>
            <p>É realizada com bambus de variados tamanhos, a depender da área corporal e tem a finalidade de relaxamento, alívio de tensões, tonificação muscular e ativação da circulação sanguínea.</p>
            <h2>Ventosaterapia</h2>
            <p>É feita a massagem associada ao método de sucção gerado na pele sobre uma área dolorosa ou pontos terapêuticos específicos. Os efeitos incluem a liberação de toxinas, levando os tecidos moles (peles e músculos) a uma drenagem dessas impurezas, melhorando a circulação sanguínea e o metabolismo celular local.  Com o efeito de melhora da circulação, as substâncias tóxicas que são retiradas pela sucção (por isso as manchas arroxeadas) favorecem a diminuição de substâncias que geram dor. Além da diminuição da dor, a ventosa proporciona alívio da tensão muscular e melhora da capacidade funcional. Alivia também emoções tóxicas em excesso.</p>
            <h2>Hai Hua</h2>
            <p>Associa-se a massagem manual ao aparelho hai-hua que combina os métodos da Medicina Tradicional Chinesa (MTC) com a tecnologia eletrônica. No aparelho, as agulhas são substituídas por dois eletrodos que possuem ímãs que liberam cargas eletromagnéticas, permitindo a estimulação de pontos e regiões do corpo humano, aliviando tensões musculares e reduzindo dores.</p>
            <h2>Reflexologia podal</h2>
            <p>O termo reflexologia se refere a ciência que estuda os efeitos dos reflexos no organismo humano. A reflexologia podal é feita através de uma técnica específica de pressão que atua em pontos reflexos precisos dos pés que correspondem a todas as partes do corpo.</p>
            <h2>Craniana</h2>
            <p>Tem como foco as regiões de escápulas, ombros, pescoço, face e crânio. Estas áreas recebem com muita facilidade e intensidade as tensões do dia a dia, principalmente as que ocorrem por tensão postural e estresse. Com toques mais profundos, trabalha a liberação terapêutica de pontos de tensão muscular (pontos gatilhos) e devido a isto, favorece um relaxamento profundo das áreas trabalhadas e, consequentemente, alívio de dores e desconfortos.</p>
            <h2>Shantala</h2>
            <p>Massagem ayurvédica para bebês a partir de 1 mês de nascidos. Melhora o sono, reduz cólicas, acalma e nutre.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
