import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import HomePage from "HomePage.js"
import ContactPage from "ContactPage.js";
import AboutPage from "AboutPage.js";
import TherapiesPage from "TherapiesPage";

import AcupuncturePage from "therapies/Acupuncture";
import AyurvedaPage from "therapies/Ayurveda";
import DrainagePage from "therapies/Drainage";
import MassagePage from "therapies/Massage";
import ReikiPage from "therapies/Reiki";
import NaturalsPage from "therapies/Naturals";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/contato">
          <ContactPage />
        </Route>
        <Route path="/sobre">
          <AboutPage />
        </Route>
        <Route path="/terapias"> 
          <Switch>
            <Route path="/terapias/acupuntura">
              <AcupuncturePage />
            </Route>
            <Route path="/terapias/ayurveda">
              <AyurvedaPage />
            </Route>
            <Route path="/terapias/drenagem">
              <DrainagePage />
            </Route>
            <Route path="/terapias/massoterapia">
              <MassagePage />
            </Route>
            <Route path="/terapias/reiki">
              <ReikiPage />
            </Route>
            <Route path="/terapias/naturais">
              <NaturalsPage />
            </Route>
            <Route index>
              <TherapiesPage />
            </Route>
          </Switch>         
        </Route>
        <Route index>
          <HomePage />
        </Route>     
      </Switch>
    </Router>
  );
}