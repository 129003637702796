import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import LogoImage from "../../images/Jasmim-Espaco-Terapeutico-logo_transparente.png";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as EmailIcon } from "feather-icons/dist/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "feather-icons/dist/icons/smartphone.svg";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import MapSection from '../maps/Map.jsx'

const Container = tw.div`relative bg-primary-100 -mx-8 -mb-8 px-0 md:px-8`;
const ThreeColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

//const Column = tw.div`md:w-1/3`;
const Column = tw.div`w-full flex flex-col items-center md:items-start md:w-1/3 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center w-4/5`;
const LogoImg = tw.img`w-8/12`;

const Description = tw.div`mt-4 text-sm w-4/5 text-justify`;

const JasmimTitle = tw.div`w-4/5 text-center font-bold mb-3 text-lg`
const JasmimInfo = tw.div`w-4/5 text-center mb-5`

const InfoList = styled.div`
  ${tw`mt-1 flex flex-col w-4/5`}
  svg {
    ${tw`text-primary-500`}
  }
`;
const Info = tw.div`items-center flex flex-row hover:text-primary-900 hover:font-bold`;
const InfoLink = styled.a`
  ${tw`cursor-pointer text-sm inline-block p-2`}
`;
const BreakAllInfoLink = styled.a`
  ${tw`cursor-pointer text-xs inline-block p-2 break-all`}
`;

const SocialLinksContainer = tw.div`mt-4 md:mt-2 flex items-start justify-center w-4/5`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-primary-500 text-gray-300 hover:bg-primary-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightMessage = tw.div`w-full text-center text-sm pb-5`;

const containerStyle = {
  width: '400px',
  height: '350px'
};

const center = {
  lat: -19.941924617951248, 
  lng: -43.923683206665636
};

export default () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD7gt58e9SVHK2goaemCcBkzKvKcbFfXH0"
  });

  const [map, setMap] = React.useState();

  const onLoad = React.useCallback(function callback(callbackMap) {
    setMap(callbackMap);    
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  return (
    <Container>
      <ThreeColumns>
        <Column>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <Description>
            Espaço terapêutico de valorização do ser, seu meio e seus ciclos através da saúde integrativa. 
            Massoterapia, ayurveda, Reiki, acupuntura, associados aos elementos da natureza, óleos, ervas, flores, 
            fitoterapia, aromaterapia, cristais e pedras.
          </Description>
          <SocialLinksContainer>   
            <SocialLink href="https://facebook.com/jasmimflorescerterapeutico/" target="_blank">
              <FacebookIcon />
            </SocialLink> 
            <SocialLink href="https://www.instagram.com/jasmimflorescerterapeutico/" target="_blank">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
        </Column>        
        <Column>
          <JasmimTitle>Jasmim Espaço Terapêutico</JasmimTitle>
          <JasmimInfo>CNPJ: 36.369.372/0001-37</JasmimInfo>
          <InfoList>
            <Info >
              <LocationIcon />
              <InfoLink href="https://goo.gl/maps/31Kgg9mLNHZGPkbM8" target="_blank">Rua Pirapetinga, 322 / Sala 403<br />Edifício Bérgamo<br />Serra - Belo Horizonte/MG</InfoLink>
            </Info>
            <Info >
              <EmailIcon />
              <BreakAllInfoLink href="mailto:contato@jasmimflorescerterapeutico.com.br" target="_blank">contato<br/>@jasmimflorescerterapeutico.com.br</BreakAllInfoLink>
            </Info>
            <Info >
              <PhoneIcon />
              <InfoLink href="tel:31994568817" target="_blank">(31) 99456-8817</InfoLink>
            </Info>
          </InfoList>
        </Column> 
        <Column>
          {isLoaded && 
            <GoogleMap 
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
              onLoad={onLoad}              
              onUnmount={onUnmount}
            >
              <Marker 
                position={center} 
              />
              { /* Child components, such as markers, info windows, etc. */ }
              <></>
            </GoogleMap>
            //<MapSection location={location} zoomLevel={17} />
          }
        </Column>     
      </ThreeColumns>
      <CopyrightMessage>Jasmim Espaço Terapêutico © 2022. Todos os direitos reservados.</CopyrightMessage>
    </Container>
  );
};
