import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-2`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 md:px-6 flex`}
`;

const Card = styled.a`
  ${tw`flex flex-col w-full mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    svg {
      ${tw`w-8 h-8 text-red-400`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Ads Management",
      description: "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    { imageSrc: SupportIconImage, title: "Video Marketing" },
    { imageSrc: CustomizeIconImage, title: "Customer Relation" },
    { imageSrc: ReliableIconImage, title: "Product Outreach" },
    { imageSrc: FastIconImage, title: "PR Campaign" },
    { imageSrc: SimpleIconImage, title: "Product Expansion" }
  ],
  title = <>Our Professional <span tw="text-primary-500">Services</span></>,
  description = null,
  target = "_blank"
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  return (
    <Container>
      <ThreeColumnContainer>        
        {cards.map((card, i) => (
          <Column key={i}>
            <Card href={card.url} target={target}>
              <span className="imageContainer">
                {card.icon}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <div className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </div>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
