import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import PhotoGallery from "components/gallery/PhotoGallery.js";
import WhatsappButton from "components/misc/WhatsappButton";

import JasmimLogo from "images/Jasmim-Espaco-Terapeutico-logo_transparente.png";
import QuisqualisFlower from "images/quisqualis-640x480.jpg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
import { ReactComponent as SmileIcon } from "feather-icons/dist/icons/smile.svg";

//Photos
import Photo1 from "images/photos/Jasmim - Fotos 004.jpg";
import Photo2 from "images/photos/Jasmim - Fotos 008.jpg";
import Photo3 from "images/photos/Jasmim - Fotos 013.jpg";
import Photo4 from "images/photos/Jasmim - Fotos 014.jpg";
import Photo5 from "images/photos/Jasmim - Fotos 022.jpg";
import Photo6 from "images/photos/Jasmim - Fotos 028.jpg";
import Photo7 from "images/photos/Jasmim - Fotos 085.jpg";
import Photo8 from "images/photos/Jasmim - Fotos 108.jpg";
import Photo9 from "images/photos/Jasmim - Fotos 160.jpg";
import Photo10 from "images/photos/Jasmim - Fotos 161.jpg";
import Photo11 from "images/photos/Jasmim - Fotos 169.jpg";
import Photo12 from "images/photos/Jasmim - Fotos 173.jpg";
import Photo13 from "images/photos/Jasmim - Fotos 201.jpg";
import Photo14 from "images/photos/Jasmim - Fotos 269.jpg";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Description = tw.div`text-justify`;
const InspirationDescription = tw.div`text-justify text-sm`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const SpaceDiv = tw.div`mb-24`;

const photos = [
  {
    src: Photo1,
    width: 1,
    height: 1
  },
  {
    src: Photo2,
    width: 1,
    height: 1
  },
  {
    src: Photo3,
    width: 1,
    height: 1
  },
  {
    src: Photo4,
    width: 1,
    height: 1
  },
  {
    src: Photo5,
    width: 1,
    height: 1
  },
  {
    src: Photo6,
    width: 1,
    height: 1
  },
  {
    src: Photo7,
    width: 1,
    height: 1
  },
  {
    src: Photo8,
    width: 1,
    height: 1
  },
  {
    src: Photo9,
    width: 1,
    height: 1
  },
  {
    src: Photo10,
    width: 1,
    height: 1
  },
  {
    src: Photo11,
    width: 1,
    height: 1
  },
  {
    src: Photo12,
    width: 1,
    height: 1
  },
  {
    src: Photo13,
    width: 1,
    height: 1
  },
  {
    src: Photo14,
    width: 1,
    height: 1
  }
];

export default () => {
  return (
    <AnimationRevealPage>
      <WhatsappButton />
      <Header />
      <MainFeature1
        subheading={<Subheading>Jasmim Espaço Terapêutico</Subheading>}
        heading = {<>Lugar de valorização do <HighlightedText>ser</HighlightedText></>}
        description = {
          <Description>Espaço terapêutico de valorização do ser, seu meio e seus ciclos através da saúde integrativa. 
            <br /><br />
            Trabalhamos com massoterapia, ayurveda, Reiki, acupuntura, associados aos elementos da natureza, óleos, ervas, flores, 
            fitoterapia, aromaterapia, cristais e pedras.
          </Description>
        }
        buttonRounded={false}
        primaryButtonText="Veja nossas terapias"
        primaryButtonUrl="/terapias"
        imageSrc = {JasmimLogo}
      />
      <MainFeature1
        subheading={<Subheading>Nossa inspiração</Subheading>}
        heading="O Jasmim"
        buttonRounded={false}
        primaryButtonText="Agendar uma consulta"
        primaryButtonUrl="https://api.whatsapp.com/send/?phone=+5531994568817&text=Ol%C3%A1!%20Desejo%20agendar%20uma%20consulta!"
        primaryButtonUrlTarget="_blank"
        description={
          <InspirationDescription>
            Nossa inspiração é o jasmim, uma flor delicada, de energia feminina, mas marcante e forte no aroma. 
            Traz, nas suas assinaturas, sorte, doçura, brilho e alegria. 
            Muito utilizada para harmonizar o humor, devolver a alegria, acalmar e nutrir o coração com amor e esperança, 
            para um crescimento com firmeza, confiança e sabedoria. 
            <br /><br />Existem várias lendas e contos espiritualistas que envolvem essa flor, 
            e giram em torno do sagrado feminino, da beleza, do cuidado e do amor universal. A flor tem cinco pétalas, lembrando uma estrela de cinco pontas, 
            o símbolo do pentagrama, que significa a transcendência do ser humano, através da consciência divina em si. 
            <br /><br />E, como coincidências não existem, essa é a nossa missão.        
          </InspirationDescription>
        }
        imageSrc = {QuisqualisFlower}
        textOnLeft={false}
      />      
      <Features
        subheading="Venha nos visitar"
        heading="Nosso espaço"
        description="Nossa estrutura é pensada para o acolhimento das pessoas, para que se conectem consigo mesmas e com nossos tratamentos."
        cards={[
          {
            icon: <LocationIcon />,
            title: "Localização",
            description: "No coração do bairro Serra em Belo Horizonte. Acesso fácil pela Avenida Afonso Pena ou Rua do Ouro."
          },
          {
            icon: <HomeIcon />,
            title: "Espaço",
            description: "Estamos no Edifício Bergamo, num ambiente preparado espaecialmente para te acolher."
          },
          {
            icon: <SmileIcon />,
            title: "Clima relaxante",
            description: "Ambiente de spa reservado, com banheira de hidromassagem, relaxante e acolhedor"
          },
        ]}
        linkText=""
      />
      <PhotoGallery photos={photos} />
      <SpaceDiv />
      <Footer />
    </AnimationRevealPage>
  );
};
