import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/reiki-healing-600x398.jpg';
import Photo2 from 'images/reiki-treatment-300x300.png';

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;

const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/reiki" secondTitle="Reiki" />
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Reiki</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} className="leftImage" align="left" />
            <p>É uma terapia integrativa, em que o terapeuta (ou mestre reikiano) estende suas mãos sob partes do corpo do paciente para canalizar energia vital universal (Prana), desbloqueando padrões negativos, a fim de restaurar o equilíbrio físico, regularizar suas funções vitais e equilibrar o campo mental e emocional. Reduz ansiedade, estresse, insônia, dores, TPM e outros, trazendo paz e saúde.</p>

            <div className="clearfix" />
            <h1>Benefícios</h1>
            <div className="clearfix" />
            <img src={Photo2} className="rightImage" align="right" />
            <p>Reiki é uma técnica de cura antiga, porém simples. Foi descoberto que ele produz resultados profundos por meio de toques suaves, mentalidades positivas e transferência de energia. O Reiki tem diversas vantagens:</p>
            <ul>
              <li>Promove harmonia e equilíbrio;</li>
              <li>Relaxa e libera a tensão do corpo;</li>
              <li>Ajuda na melhoria da qualidade do sono;</li>
              <li>Auxilia no crescimento espiritual;</li>
              <li>Ajuda na limpeza do corpo;</li>
              <li>Acelera a capacidade de autocura do corpo.</li>
            </ul>
            <div className="clearfix" />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
