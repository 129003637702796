import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/photos/Jasmim-Fotos-099-370x370.jpg';
import Photo2 from 'images/photos/Jasmim-Fotos-022-370x247.jpg';

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;

const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6 md:mt-12`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/naturais" secondTitle="Terapias Naturais" />    
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Terapias Naturais</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} alt="Terapia natural" className="rightImage" align="right" />
            <p>Terapias naturais são aquelas que utilizam os recursos disponíveis na natureza ou que utilizam métodos que não agridem o organismo para promover a saúde, curar e prevenir doenças</p>
            
            {/* <div className="clearfix" /> */}
            <h1>Sessão terapêutica</h1>
            {/* <div className="clearfix" /> */}
            <p>Tem duração média de 1h30. Mistura as técnicas terapêuticas de acordo com a necessidade do cliente, com o objetivo de tratar as desarmonias e desequilíbrios orgânicos, restaurando as emoções, a tranquilidade da mente e a saúde do corpo.</p>           

            {/* <div className="clearfix" /> */}
            <h1>Aromaterapia</h1>
            {/* <div className="clearfix" /> */}
            <p>Prática terapêutica milenar que utiliza de forma inteligente propriedades medicinais específicas dos óleos essenciais 100% puros, extraídos de várias partes de plantas para tratar, acalmar e equilibrar o corpo, a mente, as emoções e o espírito.</p>

            <div className="clearfix" />
            <h1>Cristaloterapia</h1>
            {/* <div className="clearfix" /> */}
                       
            <p>Método de tratamento que usa cristais e pedras para limpar, energizar e reequilibrar os chacras (pontos energéticos de conexão ligados às glândulas endócrinas do corpo). Os cristais nos auxiliam a ter mais harmonia e bem-estar e nos lembram de nossa verdadeira essência de unidade e conexão com a fonte. Traz saúde e equilíbrio. Melhora o funcionamento e a fluidez do corpo.</p>

            <div className="clearfix" />
            
            <h1>Fitoterapia</h1>
            <img src={Photo2} alt="Terapia natural" className="leftImage" align="left" /> 
            {/* <div className="clearfix" /> */}
            <p>Previne e trata doenças através de ervas e compostos de ervas.</p>
                                                                                
            <div className="clearfix" />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
