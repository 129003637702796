import React from "react";
import tw, { styled } from "twin.macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnJasmim.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGridJasmim.js";
import PlaceFeaturesGrid from "components/blogs/GridWithFeaturedPost.js";
import WhatsappButton from "components/misc/WhatsappButton";
import Footer from "components/footers/JasmimFooter.js";

//Instagram
import 'react-ig-feed/dist/index.css'
//------

//Images
import JasmimLogo from 'images/jasmim-logo-whatsapp-150x150.png'
import BigLogo from 'images/Jasmim-Espaco-Terapeutico-logo_transparente.png';
import CoverImage from 'images/oil-body-massage_900x.webp'

//Photos
import Photo1 from 'images/photos/400x300/Jasmim - Fotos 004-420x420.jpg';
import Photo2 from 'images/photos/400x300/Jasmim - Fotos 028-420x320.jpg';
import Photo3 from 'images/photos/400x300/Jasmim - Fotos 160-420x320.jpg';
import Photo4 from 'images/photos/400x300/Jasmim - Fotos 161-420x320.jpg';
import Photo5 from 'images/photos/400x300/Jasmim - Fotos 169-420x320.jpg';

import MovingTruckImage from 'images/moving-truck-640x480.jpg'

import TherapyPhotoAyurveda from 'images/photos/300x300/ayurveda-massagem-oleos-300x300.jpg';
import TherapyPhotoAcupuncture from 'images/photos/300x300/acupuntura-300x300.jpg';
import TherapyPhotoMassage from 'images/photos/300x300/massagem-costas-300x300.jpg';
import TherapyPhotoReiki from 'images/photos/300x300/reiki-300x300.jpg';
import TherapyPhotoDrainage from 'images/photos/300x300/drenagem-linfatica-300x300.jpg';
import TherapyPhotoNatural from 'images/photos/300x300/oleos-essenciais-300x300.jpg';


const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Description = tw.span`inline-block mt-8 text-justify`;
const imageCss = tw`rounded-4xl`;
const DescriptionElement = tw.div`text-justify`;
const InspirationDescription = tw.div`text-justify text-sm`;
const NewAddressLink = tw.a`font-bold`;

export default () => {
  
  return (
    <AnimationRevealPage disabled>
      <WhatsappButton />       
      {/* Normal Hero
      <Hero
        heading={<>Conheça nossas <HighlightedText>Terapias Integrativas</HighlightedText></>}
        description={<DescriptionElement>Aliamos a <b>massoterapia</b> (o toque) aos elementos da <b>natureza</b> (óleos, ervas, flores, plantas, vegetais, aromas, cristais e pedras), valorizando sazonalidades, regionalidades e a ciclicidade da vida para tratar corpo e mente.</DescriptionElement>}
        imageSrc={CoverImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Saiba mais"
        primaryButtonUrl="/sobre"
        actionButtonText="Agendar uma consulta!"
        actionButtonUrl="https://api.whatsapp.com/send/?phone=+5531994568817&text=Ol%C3%A1!%20Desejo%20agendar%20uma%20consulta!"
      /> */}
      <Hero
        heading={<>Estamos de <HighlightedText>Casa Nova</HighlightedText></>}
        description={<DescriptionElement>O Jasmim Florescer Terapêutico tem agora um novo endereço! Preparamos nosso novo espaço para te receber e acolher com conforto e aconchego. Venha conhecer: <NewAddressLink href="https://goo.gl/maps/yyf6MjupTDEhXwXq6" target="_blank">Rua Pirapetinga, 322 - Sala 403, Edifício Bérgamo, no Bairro Serra em BH.</NewAddressLink></DescriptionElement>}
        imageSrc={MovingTruckImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Saiba mais"
        primaryButtonUrl="/sobre"
        actionButtonText="Agendar uma consulta!"
        actionButtonUrl="https://api.whatsapp.com/send/?phone=+5531994568817&text=Ol%C3%A1!%20Desejo%20agendar%20uma%20consulta!"
      />
      <MainFeature
        subheading={<Subheading>Cuida da sua saúde</Subheading>}
        heading={<>Conheça nossas <HighlightedText>Terapias Integrativas</HighlightedText></>}
        buttonRounded={false}
        primaryButtonText="Agendar uma consulta"
        primaryButtonUrl="https://api.whatsapp.com/send/?phone=+5531994568817&text=Ol%C3%A1!%20Desejo%20agendar%20uma%20consulta!"
        primaryButtonUrlTarget="_blank"
        description={
          <InspirationDescription>
            Aliamos a <b>massoterapia</b> (o toque) aos elementos da <b>natureza</b> (óleos, ervas, flores, 
            plantas, vegetais, aromas, cristais e pedras), valorizando sazonalidades, regionalidades 
            e a ciclicidade da vida para tratar corpo e mente.      
          </InspirationDescription>
        }
        imageSrc = {CoverImage}
        textOnLeft={false}
      /> 
      <MainFeature
        subheading={<Subheading>Cuidados para Corpo e Alma</Subheading>}
        heading={
          <>
            <HighlightedText>Jasmim</HighlightedText>
            <br />
            Espaço Terapêutico
          </>
        }
        description={
          <Description>
            Espaço terapêutico de valorização do ser, seu meio e seus ciclos através da saúde integrativa. <br /><br /> Trabalhamos
            com massoterapia, ayurveda, Reiki, acupuntura, associados aos elementos da natureza, óleos, ervas, flores, fitoterapia, 
            aromaterapia, cristais e pedras.
            dolore magna aliqua.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="Sobre nós"
        primaryButtonUrl="/sobre"
        imageSrc={BigLogo}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <PlaceFeaturesGrid
        subheading="Aguardamos sua visita"
        heading={<>Conheça nosso <HighlightedText>Espaço</HighlightedText></>}
        posts = {[
          {
            postImageSrc: Photo1,            
            title: "Localização privilegiada",
            description:
              "Estamos no Edifício Bérgamo, próximo da Av. Afonso Pena, no bairro Serra em BH",
            authorName: "Jasmim",
            authorProfile: "Espaço Terapêutico",
            authorImageSrc: JasmimLogo,
            url: "/sobre",
            featured: true
          },
          {
            postImageSrc: Photo2,
            title: "Ambiente relaxante",
            authorName: "",
            url: "/sobre"
          },
          {
            postImageSrc: Photo3,
            title: "Clima de SPA",
            authorName: "",
            url: "/sobre"
          },
          {
            postImageSrc: Photo4,
            title: "Banheira de hidromassagem",
            authorName: "",
            url: "/sobre"
          },
          {
            postImageSrc: Photo5,
            title: "Tudo para seu conforto e relaxamento",
            authorName: "",
            url: "/sobre"
          }
        ]
      }
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Nossas <HighlightedText>Terapias</HighlightedText>
          </>
        }
        tabs = {{
          Terapias: [            
            {
              imageSrc: TherapyPhotoAcupuncture,
              title: "Acupuntura",
              content: "Terapia milenar chinesa com foco em estímulos de pontos específicos do corpo.",
              url: "/terapias/acupuntura"
            },
            {
              imageSrc:TherapyPhotoAyurveda,
              title: "Ayurveda",
              content: "Sistema de cura indiano que promove a saúde integral do paciente.",
              url: "/terapias/ayurveda"
            },
            {
              imageSrc: TherapyPhotoDrainage,
              title: "Drenagem Linfática",
              content: "Drena líquidos acumulados, elimina toxinas e fortalece o sistema imunológico.",
              url: "/terapias/drenagem"
            },
            {
              imageSrc: TherapyPhotoMassage,
              title: "Massoterapia",
              content: "Além dos efeitos relaxantes, as massagens atuam com grande precisão em curas.",
              url: "/terapias/massoterapia"
            },
            {
              imageSrc: TherapyPhotoReiki,
              title: "Reiki",
              content: "Técnica japonesa de cura pela canalização da energia vital universal.",
              url: "/terapias/reiki"
            },
            {
              imageSrc:TherapyPhotoNatural,
              title: "Terapias Naturais",
              content: "Utilizam os recursos disponíveis na natureza para promover a saúde, curar e prevenir doenças.",
              url: "/terapias/naturais"
            }
          ]
        }}
      />
      
      {/* <InstagramFeed token="IGQVJWVktFTjJ1cmh1dDM0VWkxVzY0ZAWVieEstdl9MSmk1OGRIMDZAsSmRsQ09rcml1cHVLU1p0dm9yTlRYOFN4MjdNQ0k2cGtESEJoVk1OcnM1eDYzX3o5SzNiZAzJjTW1yQlZAsc2VtSXNLRXBVOGNIVAZDZD"  counter="6"/>  */}

      <Footer />
    </AnimationRevealPage>
  );
}
