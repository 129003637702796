import tw from "twin.macro";

import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

export const BreadcrumbContainer = tw.div`flex-auto flex justify-between items-center max-w-screen-xl mx-auto mt-12 mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 text-gray-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-primary-200 text-primary-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
export const BreadcrumbHomeIcon = tw(HomeIcon)`w-4`;
export const BreadcrumbSeparator = tw(ChevronRightIcon)`w-4 mx-3`
export const BreadcrumbItem = tw.a``;

export default ({ firstHref, firstTitle, secondHref, secondTitle }) => {

   return (
      <BreadcrumbContainer>        
         <BreadcrumbItem href="/"><BreadcrumbHomeIcon /></BreadcrumbItem>
         <BreadcrumbSeparator />
         <BreadcrumbItem href={firstHref}>{firstTitle}</BreadcrumbItem>
         <BreadcrumbSeparator />
         <BreadcrumbItem href={secondHref}>{secondTitle}</BreadcrumbItem>
      </BreadcrumbContainer>
   );
 };
 
 

 
