import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/photos/300x300/drenagem-linfatica-300x300.jpg';
import Photo2 from 'images/photos/Jasmim - Fotos 266-500x333.jpg';
import Photo3 from 'images/photos/Jasmim - Fotos 085-500x333.jpg'

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;

const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/drenagem" secondTitle="Drenagem" />
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Drenagem Linfática</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} className="rightImage" align="right" />
            <p>É uma técnica especializada, que atua no sistema linfático, eliminando toxinas e fortalecendo o sistema imunológico. Drena líquidos acumulados entre as células, melhorando a circulação e a oxigenação desse tecido. É considerada um elixir para a saúde do corpo, além de ser muito relaxante</p>           
            
            {/* <div className="clearfix" /> */}
            <h1>Drenagem Clássica</h1>
            {/* <div className="clearfix" /> */}
            <p>Através de uma massagem suave e precisa na pele, se estimula o sistema linfático a atuar no organismo de modo eficaz, reduzindo inchaço, melhorando a eliminação de toxinas e mantendo o organismo forte e saudável para cumprir suas funções fisiológicas em harmonia. Muito utilizada para tratar TPM, dores nas pernas, inchaços, regular a função intestinal, melhora o viço da pele, unhas e cabelos.</p><p>A drenagem linfática proporciona diversos benefícios:</p>
            <ul>
              <li>Combate o inchaço;</li>
              <li>Combate a celulite;</li>
              <li>Favorece a cicatrização;</li>
              <li>Ajuda na recuperação de lesões;</li>
              <li>Reduz hematomas;</li>
              <li>Melhora a circulação sanguínea;</li>
              <li>Oxigena os tecidos;</li>
              <li>Elimina toxinas;</li>
              <li>Ajuda no pós-operatório.</li>
            </ul>

            <div className="clearfix" />
            <h1>Gestante</h1>
            <div className="clearfix" />
            <p>É um dos métodos mais recomendados para amenizar o inchaço durante a gravidez, e contribuir para o bom funcionamento do sistema circulatório e linfático, reduzindo desconfortos e dores.</p>

            <div className="clearfix" />
            <h1>Método Renata França</h1>
            {/* <div className="clearfix" /> */}
            <img src={Photo3} className="rightImage" align="right" />
            <p>Técnica especial de Drenagem linfática, que associa manobras para redução de medidas.</p>

            {/* <div className="clearfix" /> */}
            <h1>Método Amanda Fernandes</h1>
            {/* <div className="clearfix" /> */}
            <p>Técnica especial de drenagem linfática, que associa manobras e movimentos corporais para redução de medidas.</p>
               
            <div className="clearfix" />
            <h1>Pós-operatório</h1>
            <div className="clearfix" />
            <p>Drenagem linfática especial, realizada após procedimentos cirúrgicos como lipoaspiração, abdominoplastia, mamoplastia, plásticas faciais e outras. Visa à redução dos desconfortos e acelera a recuperação.</p>

            
            <div className="clearfix" />
            <h1>Com Vibrocell</h1>
            <div className="clearfix" />
            <img src={Photo2} className="leftImage" align="left" />
            <p>É realizada a drenagem linfática associada ao vibrocell, um equipamento que proporciona uma massagem mecânica profunda. Ele mobiliza os tecidos de forma intensa, favorecendo o sistema circulatório e linfático, reduzindo dores e queimação nas pernas, inchaço, celulites e auxiliando na redução de medidas.</p>
            <div className="clearfix" />
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
