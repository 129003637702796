import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/JasmimFooter.js";
import WhatsappButton from "components/misc/WhatsappButton";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase  } from "components/misc/Headings.js";
import Breadcrumb from "components/misc/Breadcrumb";

//Photos
import Photo1 from 'images/photos/300x300/acupuntura-300x300.jpg';
import Photo2 from 'images/acupuntura-quadrada-500x500.jpg';

const PageTitle = tw(SectionHeading)`w-full mb-12`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;

const Text = styled.div`
  ${tw`text-base md:text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-justify`}
  }
  h1 {
    ${tw`text-3xl bg-primary-100 text-primary-500 px-4 transform -skew-x-12 inline-block font-bold mt-12 mb-8`}
  }
  h2 {
    ${tw`text-2xl bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block my-6`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside text-justify mt-6`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal text-justify`}
      }
    }    
  }
  img {
    ${tw`rounded-md`}
  }
  .leftImage {
    ${tw`mb-6 sm:mr-6`}
  }
  .rightImage {
    ${tw`mb-6 sm:ml-6 `}
  }
  .clearfix {
    ${tw`clear-both`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>      
      <WhatsappButton />
      <Header />
      <Breadcrumb firstHref="/terapias" firstTitle="Terapias" secondHref="/terapias/acupuntura" secondTitle="Acupuntura" />
      
      <Container>
        <ContentWithPaddingXl>
          <Subheading>Terapias</Subheading>
          <PageTitle>
            <HighlightedText>Acupuntura</HighlightedText>
          </PageTitle>
          <Text>
            <img src={Photo1} className="leftImage" align="left" />
            <p>A acupuntura é uma terapia milenar de origem chinesa, que consiste na aplicação de agulhas bem finas, em pontos específicos do corpo, para melhorar a imunidade e tratar problemas emocionais e doenças físicas como sinusite, asma, enxaqueca, dores, artrite, insônia, regulação intestinal, TPM, menopausa, ansiedade, depressão.</p>

            <p>As técnicas de acupuntura baseiam-se na ideia de que o corpo é composto de energia, acumulada em várias regiões, que são chamados de meridianos. Se o fluxo de energia nestes meridianos estiver desequilibrado provoca inflamação no corpo, causando sintomas como dor, cansaço e fraqueza.</p>

            <p>Por isso, o objetivo do tratamento com acupuntura é restabelecer o equilíbrio do corpo, facilitando a circulação de energia, desencadeando efeitos analgésicos e anti-inflamatórios comprovados pela ciência.</p>
            
            <div className="clearfix" />
            <h1>Benefícios</h1>
            <div className="clearfix" />
            <img src={Photo2} className="rightImage" align="right" />
            <p>São bastante difundidos os incríveis efeitos da acupuntura no que diz respeito à redução da dor crônica e à melhoria da qualidade de vida em vários distúrbios musculoesqueléticos. A acupuntura proporciona diversos benefícios:</p>
            <ul>
              <li>Redução de estresse;</li>
              <li>Redução da dor nas costas, a tensão no pescoço e alivia a dor nas articulações das mãos e braços;</li>
              <li>Alívio das dores de cabeça;</li>
              <li>Redução da tensão e cansaço ocular;</li>
              <li>Melhoria do sistema imunológico;</li>
              <li>Melhora na energia mental;</li>
              <li>Alívio de condições digestivas;</li><li>Melhoria em alergias;</li>
              <li>Redução do desejo de fumar;</li>
              <li>Diminuição de lesões por esforço repetitivo.</li>
            </ul>
            
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
