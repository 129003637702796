import React, { useEffect } from "react";
import tw, { styled } from "twin.macro"; //eslint-disable-line
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import JasmimLogo from 'images/jasmim-logo-whatsapp-150x150.png';

const WhatsappContainer = styled.div`
  div {
    ${tw`z-999`}
  }
`;

export default () => {
  useEffect(() => {
    document.getElementsByClassName('waButton')[0].getElementsByTagName('input')[0].setAttribute("value", "Olá! Gostaria de mais informações.");
  });

   return (
      <WhatsappContainer>
        <FloatingWhatsApp 
          phoneNumber="+5531994568817" 
          avatar={JasmimLogo} 
          accountName="Jasmim" 
          statusMessage="Disponível" 
          chatMessage="Olá! 🤝 Como podemos ajudar?" 
          placeholder="Mensagem..." 
          defaultMessage="Olá! Gostaria de mais informações..." 
          allowClickAway="true" 
          allowEsc="true"
          className={"waButton"}
        />
      </WhatsappContainer>
   );
}